<template>
  <div class="score">
    <span v-if="!card.blanked">
      {{ card.scoreBaseStrength }}
    </span>
    <span v-else class="score blanked">{{ $t('blanked') }}</span>
    <span
        v-if="card.scoreBonus !== 0"
        class="score "
        :class="{bonus: card.scoreBonus > 0, penalty: card.scoreBonus < 0}">
            <span v-if="card.scoreBonus > 0">+</span>{{ card.scoreBonus }}
          </span>
  </div>
</template>

<script>
export default {
  props: {
    card: Object
  }
}
</script>