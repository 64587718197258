<template>
  <span v-html="$t(code, formattedParams)"></span>
</template>

<script>
export default {
  props: {
    code: String,
    params: Object,
    background: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    formattedParams() {
      let res = {}
      if (this.params.card)
        res.card = '<span class="card-name ' + this.params.card.suit + '">'
            + this.$t('cards.' + this.params.card.name)
            + '</span>'
      if (this.params.suit) {
        let clazz = this.background ? '' : 'suit ' + this.params.suit
        res.suit = '<span class="' + clazz + '">'
            + this.$t('suits.' + this.params.suit)
            + '</span>'
      }
      return res
    }
  }
}
</script>