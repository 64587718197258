<template>
  <img :src="require('@/assets/cards/' + this.$i18n.locale + '/' + imgName + '.jpg')"
       :srcset="require('@/assets/cards/' + this.$i18n.locale + '/' + imgName + '.jpg') + ' 2x'"
       class="card-img shadow"
       draggable="true"
       height="100%"
       @dragstart="startDrag($event, card)"
       @click="$emit('select', card)"/>
</template>

<script>
export default {
  props: {
    card: Object
  },
  computed: {
    imgName(){
      return this.card.imgName || this.card.name
    }
  },
  methods: {
    startDrag: (evt, card) => {
      evt.dataTransfer.dropEffect = 'move'
      evt.dataTransfer.effectAllowed = 'move'
      evt.dataTransfer.setData('cardName', card.name)
      console.info(evt.dataTransfer.getData('cardName'))
    }
  }
}
</script>

<style scoped>
.card-img {
  border-radius: 4%;
  box-sizing: border-box;
  min-height: 100%;
  width: auto;
}

img {
}
</style>