<template>
  <div class="pile-card-area">
    <div
        v-for="card in cards"
        :key="card.name"
        class="card-wrapper"
        :style="{maxWidth: (95 / cards.length) + '%', minWidth: (95 / maximumCardsInSelectedPile) + '%'}">
      <card-score class="card-score margin-left"
                  v-if="showScoringInfo"
                  :card="card"/>
      <div class="card-image-wrapper">
        <card-image
            :card="card"
            @select="$emit('select', card)"/>
      </div>
      <div class="relation-area margin-left" v-if="showScoringInfo">

        <!-- can have relation but is not related yet -->
        <div v-if="card.relationType !== 'none' && cardRelationUnset(card)"
             @click="$emit('editRelation', card)" class="card-relation">
          <span style="border: 1px solid white; padding: 0.2rem;">{{ $t('editRelation') }}</span>
        </div>

        <!-- has an related card  -->
        <div v-if="card.relatedCard && !card.relationAffectsSelf"
             class="card-relation"
             style="white-space: nowrap;"
             @click="$emit('editRelation', card)">
          <icon icon="chevron-right" size="1x"/>
          <formatted code="card"
                     class="transparent-white-background card-relation-text"
                     :params="{card:card.relatedCard}"/>
        </div>

        <!--has a different name (e.g. copies a card like Doppelgaenger, Mirage, Shapeshifter-->
        <div v-if="!card.blanked && card.name !== card.scoreName"
             class="card-relation"
             @click="$emit('editRelation', card)">
          <formatted code="card"
                     class="transparent-white-background card-relation-text"
                     :params="{card:{name:card.scoreName,suit:card.scoreSuit}}"/>
        </div>

        <!-- has a different suit but name remains unchanged (e.g. effected BookOfChanges, Island) -->
        <div v-else-if="!card.blanked && card.suit !== card.scoreSuit && card.name === card.scoreName"
             class="card-relation">
          <formatted code="suit"
                     class="transparent-white-background card-relation-text"
                     :class="card.scoreSuit"
                     :params="{suit:card.scoreSuit}"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CardImage from "@/components/CardImage";
import CardScore from "@/components/CardScore";
import Formatted from "@/components/Formatted";
import {mapGetters} from "vuex";

export default {
  components: {Formatted, CardScore, CardImage},
  props: {
    cards: Array,
    showScoringInfo: {
      type: Boolean,
      default: false
    },
    maxCardHeightInPercentageOfViewportHeight: {
      type: Number,
      default: 65
    }
  },
  computed: {
    ...mapGetters(['maximumCardsInSelectedPile'])
  },
  methods: {
    cardRelationUnset(card) {
      return card.relationType !== 'none' && !card.relatedCard
    }
  }
}
</script>
<style scoped>

.pile-card-area {
  position: relative;
  display: flex;
  max-width: 95%;
  width: 95%;
  top: 5%;
  left: 2%;
  height: 95%;
  overflow-x: visible;
}

.card-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.card-image-wrapper {
  display: flex;
  flex: 1;
}

.margin-left {
  margin-left: 1rem;
}

.card-score {
  flex: 0;
  text-align: left;
  font-size: large;
}

.relation-area {
  flex: 0;
  min-height: 20%;
  max-height: 20%;
  padding-top: 0.5rem;
}


.card-relation {
  display: block;
  text-align: left;
  margin-bottom: 0.7rem;
}

.card-relation-text {
  padding: 0.3rem;
  border-radius: 0.3rem;
  white-space: nowrap;
  cursor: pointer;
}
</style>